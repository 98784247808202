import React from 'react';
import ExamTemplate from '../../components/ExamTemplate';
import { Progress, ProgressMeasureLocation } from '@patternfly/react-core';


function EX288() {
  const tabs = [
    {
      title: 'Work with Red Hat OpenShift Container Platform',
      content: (
        <div>
          <Progress
            value={10}
            title="Completion"
            measureLocation={ProgressMeasureLocation.top}
          />
          <h2>Grant web console user cluster-admin access to install operator from OperatorHub</h2>
          <p>
            Use the following command to grant cluster-admin access:
          </p>
          <pre>
            <code>oc adm policy add-cluster-role-to-user cluster-admin &lt;user-name&gt;</code>
          </pre>
        </div>
      ),
    },
    {
      title: 'Deploy multi-container applications',
      content: (
        <div>
        <Progress
          value={20}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Create VMs from Template</h2>
          <p>
            Learn how to create virtual machines using predefined templates.
          </p>
        </div>
      ),
    },
    {
      title: 'Troubleshoot application builds and deployment issues',
      content: (
        <div>
        <Progress
          value={30}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            To configure a service to access a VM on a node or a pod, you must start with a label.
          </p>
          <ul>
            <li>On the VM, the label goes to the <code>spec.template.metadata.labels</code> section.</li>
            <li>You can apply the label to the virt-launcher pod if you don't want to restart the VM; the label applies immediately.</li>
            <li>You then apply the label to the service.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Work with configuration maps and secrets',
      content: (
        <div>
        <Progress
          value={40}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Service Types</h2>
          <ul>
            <li><strong>ClusterIP</strong>: For connections within the cluster ONLY.</li>
            <li><strong>NodePort</strong>: For external client access.</li>
            <li><strong>LoadBalancer</strong>: Distributes traffic across multiple servers.</li>
          </ul>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Work with the source-to-image (S2I) framework',
      content: (
        <div>
        <Progress
          value={60}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            Understand how to perform node maintenance and update OpenShift Virtualization.
          </p>
        </div>
      ),
    },
    {
      title: 'Work with templates',
      content: (
        <div>
        <Progress
          value={80}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            Understand how to perform node maintenance and update OpenShift Virtualization.
          </p>
        </div>
      ),
    },
    {
      title: 'Work with OpenShift Pipelines',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
          <h2>Install OpenShift Pipelines Operator** from OperatorHub.</h2>
              - Create a Pipeline using Tekton:
                ```yaml
                apiVersion: tekton.dev/v1beta1
                kind: Pipeline
                metadata:
                  name: my-pipeline
                spec:
                  tasks:
                    - name: build-task
                      taskRef:
                        name: buildah
                ```
                Apply with:
                ```bash
                oc apply -f my-pipeline.yaml
                ```
          </p>
        </div>
      ),
    },
    // ... Add other exam objectives similarly
  ];

  return (
    <ExamTemplate title="Red Hat OpenShift Developer II: Building and Deploying Cloud-native Applications" tabs={tabs} />
  );
}

export default EX288;
