import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';

function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);

  const onToggle = (isOpen) => {
    setIsOpen(isOpen);
  };

  const onSelect = () => {
    setIsOpen(!isOpen);
  };
  
  const cliItems = [
    { label: 'podman', path: '/cli/podman' },
    { label: 'oc', path: '/cli/oc' },
    { label: 'tekton (Premium)', path: '/cli/tekton' },
    { label: 'odo (Premium)', path: '/cli/odo' },
    { label: 'virtctl (Premium)', path: '/cli/virtctl' },
    { label: 'rosa (Premium)', path: '/cli/rosa' },
    { label: 'aro (Premium)', path: '/cli/aro' },
    { label: 'ceph (Premium)', path: '/cli/ceph' },
    { label: 'helm (Premium)', path: '/cli/helm' },
    { label: 'kustomize (Premium)', path: '/cli/kustomize' },
  ];

  const tutorialItems = [
    { label: 'Podman', path: '/tutorials/Podman' },
    { label: 'ARO (Premium)', path: '/tutorials/ARO' },
    { label: 'Virtualization (Premium)', path: '/tutorials/Virtualization' },
    { label: 'ROSA (Premium)', path: '/tutorials/ROSA' },
    { label: 'ODF', path: '/tutorials/DataFoundation' },
    { label: 'CICD', path: '/tutorials/CICD' },
    { label: 'ARO (Premium)', path: '/tutorials/ARO' },
    { label: 'OpenShift', path: '/tutorials/OpenShift' },
    { label: 'Linux', path: '/tutorials/Linux' },
    { label: 'Ansible (Premium)', path: '/tutorials/Ansible' },
    { label: 'Kubernetes', path: '/tutorials/Kubernetes' },
    { label: 'Documentation', path: '/pages/Documentation' },
  ];

  const examItems = [
    { label: 'EX188', path: '/exams/EX188' },
    { label: 'EX280', path: '/exams/EX280' },
    { label: 'EX288', path: '/exams/EX288' },
    { label: 'EX267 (Premium)', path: '/exams/EX267' },
    { label: 'EX316 (Premium)', path: '/exams/EX316' },
    { label: 'EX370 (Premium)', path: '/exams/EX370' },
  ];

  return (
    <nav className="bg-red-700 p-4 relative z-50">
      <ul className="flex justify-around list-none text-white font-semibold">
        <li>
          <Link to="/" className="hover:text-gray-300">Home</Link>
        </li>
        <li>
          <Link to="/updates" className="hover:text-gray-300">Updates</Link>
        </li>
        <li>
          <Link to="/tutorials" className="hover:text-gray-300">Tutorials</Link>
        </li>
        <li>
          <Link to="/cli-guides" className="hover:text-gray-300">CLI Guides</Link>
        </li>
        <li>
          <Link to="/exams" className="hover:text-gray-300">Exam Prep</Link>
        </li>
        <li>
          <Link to="/about" className="hover:text-gray-300">About</Link>
        </li>
        {/* Add other links */}
      </ul>
    </nav>
  );
}

export default Navbar;
