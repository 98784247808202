import React from 'react';
import ExamTemplate from '../../components/ExamTemplate';
import { Progress, ProgressMeasureLocation } from '@patternfly/react-core';


function EX267() {
  const tabs = [
    {
      title: 'Configure and manage RHOAI Operator',
      content: (
        <div>
          <Progress
            value={100}
            title="Completion"
            measureLocation={ProgressMeasureLocation.top}
          />
          <h2> OperatorHub</h2>
          <p>
            Install the RHOAI and the Red Hat Openshift Serverless operators
          </p>
          <pre>
            <code>oc adm policy add-cluster-role-to-user cluster-admin &lt;user-name&gt;</code>
          </pre>
        </div>
      ),
    },
    {
      title: 'Work with data science projects',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Create VMs from Template</h2>
          <p>
            Learn how to create virtual machines using predefined templates.
          </p>
        </div>
      ),
    },
    {
      title: 'Use data science workbenches',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            To configure a service to access a VM on a node or a pod, you must start with a label.
          </p>
          <ul>
            <li>On the VM, the label goes to the <code>spec.template.metadata.labels</code> section.</li>
            <li>You can apply the label to the virt-launcher pod if you don't want to restart the VM; the label applies immediately.</li>
            <li>You then apply the label to the service.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Use Git to manage Jupyter notebooks collaboratively',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h2>Service Types</h2>
          <ul>
            <li><strong>ClusterIP</strong>: For connections within the cluster ONLY.</li>
            <li><strong>NodePort</strong>: For external client access.</li>
            <li><strong>LoadBalancer</strong>: Distributes traffic across multiple servers.</li>
          </ul>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Work with machine learning models',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            Understand how to perform node maintenance and update OpenShift Virtualization.
          </p>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Save and load models',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
            Understand how to perform node maintenance and update OpenShift Virtualization.
          </p>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Create data science pipelines',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <p>
          Create pipelines with Elyra
          Create pipelines with Kubeflow
          </p>
        </div>
      ),
    },
    // ... Add other exam objectives similarly
  ];

  return (
    <ExamTemplate title="Red Hat Certified Specialist in OpenShift AI Exam Objectives" tabs={tabs} />
  );
}

export default EX267;
