import React from 'react';
import ExamTemplate from '../../components/ExamTemplate';
import { Progress, ProgressMeasureLocation } from '@patternfly/react-core';

function EX370() {
    const tabs = [
        {
            title: 'Manage OpenShift Container Platform',
            content:(
            <div>    
            <Progress
            value={10}
            title="Completion"
            measureLocation={ProgressMeasureLocation.top}
          />
          <p>
            <h2>Use the command line interface to manage and configure an OpenShift Container Platform cluster</h2>
            <p>
                To connect to the OpenShift Container Platform cluster, you'll need to use the oc CLI command. Review the oc cli page for more information.
                'oc login -u username -p password openshift-console-url'
                Select the project 
                'oc project project-name'
            </p>
            <ol>
              <li>Use the web console to manage and configure an OpenShift Container Platform cluster</li>
              <li>Create and delete projects</li>
              <li>Create, edit, and delete ConfigMaps and secrets</li>
              <li>Import, export, and configure Kubernetes resources</li>
              <li>Examine resources and cluster status</li>
              <li>View logs</li>
              <li>Monitor cluster events and alerts</li>
              <li>Troubleshoot common cluster events and alerts</li>
              <li>Use product documentation</li>
            </ol>
          </p>
          </div>
            ),
        },
        {
            title: 'Deploy OpenShift Data Foundation using internal mode',
            content:(
              <div>  
                  <Progress
                  value={20}
                  title="Completion"
                  measureLocation={ProgressMeasureLocation.top}
                />
                <h2>Data Foundation Components</h2>
                <p>
                    Whether installing OpenShift Data Foundation using internal mode via web console or command line, 
                    there are a series of components that need to installed and configured.
                    <ol>
                        <li>Create the projects to install the Local Storage and Container Storage operators</li>
                        <li>Add labels to the nodes where you want to attach the volumes</li>
                        <li>Local Storage Operator:</li>
                        <li>Local Volume Discovery</li>
                        <li>Local Volume Set</li>
                        <li>Container Storage</li>
                        <li>Storage Cluster</li>
                    </ol>
                </p>
                <h2>Deploy OpenShift Data Foundation from the web console</h2>
                <p>
                  <ol>
                    <li>Install the Local Storage Operator (Creates the openshift-local-storage namespace) </li>
                    <li>Create a Local Volume Discovery</li>
                    <li>Create a Local Volume Set</li>
                    <li>Install the Container Storage Operator (Creates the openshift-storage namespace)</li>
                    <li>Configure the Storage Cluster</li>
                  </ol>
                </p>
                <h2>Deploy OpenShift Data Foundation from the command line</h2>
                <p>
                  <ol>
                    <li>lso-operatorsgroup.yml</li>
                    <li>lso-subscription.yml</li>
                    <li>localvolumediscovery.yml</li>
                    <li>localvolumeset.yml</li>
                    <li>ocs-operatorgroup.yml</li>
                    <li>ocs-subscription.yml</li>
                    <li>storagecluster.yml: usese the localvolume set as storage class</li>
                  </ol>
                </p>
              </div>
            ),
        },
        {
            title: 'Configure OpenShift cluster services to use Red Hat OpenShift Data Foundation',
            content:(
              <div>  
                <Progress
                  value={30}
                  title="Completion"
                  measureLocation={ProgressMeasureLocation.top}
                />
                <p>
                  Configure the internal image registry to Use Red Hat OpenShift  Data Foundation
                  Configure monitoring to use Red Hat OpenShift Data Foundation
                  Note: When you create a new object bucket claim, a secret with the corresponding name gets created
                </p>
              </div>
            ),
        },
        {
            title: 'Deploy and manage applications and images',
            content:(
                <div>  
                  <Progress
                    value={40}
                    title="Completion"
                    measureLocation={ProgressMeasureLocation.top}
                  />
                  <h2>Deploy applications to Red Hat OpenShift Container Platform using the web console</h2>
                  <p>

                  </p>
                  <h2>Deploy applications to Red Hat OpenShift Container Platform using the command line</h2>
                  <p>

                  </p>
                  <h2>Configure applications to use ConfigMaps and secrets</h2>
                  <p>

                  </p>
                  <h2>Understand and use image tags</h2>
                  <p>

                  </p>
                  <h2>Create and edit external routes</h2>
                  <p>
                    To create a route: oc expose svc service name
                    To edit a route: 
                  </p>
                  
                </div>
            ),
        },
        {
            title: 'Troubleshoot applications in OpenShift',
            content:(
                <div>  
                  <Progress
                    value={50}
                    title="Completion"
                    measureLocation={ProgressMeasureLocation.top}
                  />
                  <p>
                    <h2>Understand the description of application resources.</h2>
                    <p>

                    </p>
                    <h2>View and use application logs</h2>
                    <p>

                    </p>
                    <h2>Inspect running applications</h2>
                    <p>

                    </p>
                    <h2>Connect to containers running in a pod</h2>
                    <p>

                    </p>
                    <h2>Copy resources to and from containers running in a pod</h2>
                    <p>

                    </p>
                  </p>
                </div>
            ),
        },
        {
            title: 'Configure application workloads to use Red Hat OpenShift Data Foundation file and block storage',
            content:(
                <div>  
                  <Progress
                    value={60}
                    title="Completion"
                    measureLocation={ProgressMeasureLocation.top}
                  />
                  <p>
                    <h2>Identify Ceph components needed for a Red Hat OpenShift Data Foundation Implementation</h2>
                    <p> 
                        Ceph is an enterprise-ready software defined storage solution. It can scale to multipetabytes. 
                        It's built on-top of the RADOS (Reliable Autonomic Distributed Object Store) backend. 
                        Ceph is installed as a cluster on RHOCP.

                        <ol>
                          <li>Rook-Ceph Operator: Manages Ceph</li>
                          <li>Ceph Services </li>  
                          <li>Ceph logs for operator, file and block storage</li>  
                        </ol>
                        To list Ceph components from the CLI, use the following command:
                        'oc get pods -n openshfift-storage'
                    </p>
                    <h2>Configure applications to use Red Hat OpenShift Data Foundation file storage</h2>
                    <p>
                        You can use the cephfs storage class for file storage.
                        use cases for file storage inclues:
                        <ol>
                          <li>Serving web content</li>
                          <li>File sharing service</li>
                          <li>Archival backup</li>
                          <li>FTP utility</li>    
                        </ol>
                    </p>
                    <h2>Configure applications to use Red Hat OpenShift Data Foundation block storage</h2>
                    <p>
                      Block storage solutions include SAM and iSCSI technologies. They provide access to block devices for storage.
                      Some of the advantages of block storage includes optimized computation for heavy data workloads. 
                      You can use the ceph rbd storage class for block device storage. RBD stands for (RADOS Block Device)
                      Use cases for block storage includes:
                      <ol>
                        <li>Relational databases</li>
                        <li>Virtual manchines</li>
                        <li>High performance computing</li>
                        <li>Server side applications</li>
                      </ol>
                    </p>
                    <h2>Configure custom storage classes</h2>
                    <p>
                      You can create custom storage classes if the openshift provided defaults do not meet your particular use case.
                      The basics fields for configuring a custom storage class are:
                      <ol>
                        <li>privisioner: Indentified the source of the storage(AWS, Ceph, Azure, IBM)</li>
                        <li>parameters: Enables configuration for the provisioner</li>
                        <li>reclaimPolicy: Defines what to do when the storage is released</li>
                      </ol>
                      Note: Storage classes are globally scoped. This means that they are available in all projects/namespaces. 
                      Also, they can only be created by cluster or storage admins (users that have either the cluster-admin or storage-admin role).
                    </p>
                  </p>
                </div>
            ),
        },
        {
            title: 'Manage Red Hat OpenShift Data Foundation block and file storage capacity',
            content:(
                <div>  
                  <Progress
                    value={70}
                    title="Completion"
                    measureLocation={ProgressMeasureLocation.top}
                  />
                  <p>
                    <h2>Monitor Red Hat OpenShift Data Foundation cluster health</h2>
                    <p>
                        In web console go to Home -- Overview -- Persistent Storage. Scroll down for details.
                        
                    </p>
                    <h2>Configure storage quotas and permissions</h2>
                    <p>
                        To view co has permissions to create PVCs run <br/>
                        oc adm policy who-can create persistentvolumeclaims <br/> 
                        To grant permission to a user in cli: <br/>
                        oc adm policy add-cluster-role-to-user storage-admin username -n openshift
                        ResourceQuotas:
                        oc get ResourceQuotas 
                        apiVersion: v1
                        kind: ResourceQuota 
                        metadata: 
                          name: pvc-quota 
                        spec: 
                          hard:
                            persistentvolumneclaims: "1"
                    </p>
                    <h2>Extend application storage for Red Hat OpenShift Data Foundation</h2>
                    <p dangerouslySetInnerHTML={{
                      __html: `oc patch pvc/pg-capacity-extend-ge \
                      -p '{"spec":{"resources":{"requests": {"storage": "150M"}}}}'`
                    }} />
                    <h2>Add disks to a Red Hat OpenShift Data Foundation Cluster</h2>
                    <p>

                    </p>
                  </p>
                </div>
            ),
        },
        {
            title: 'Perform backup and restore of Kubernetes block and file volumes',
            content:(
                <div>  
                  <Progress
                    value={80}
                    title="Completion"
                    measureLocation={ProgressMeasureLocation.top}
                  />
                  <p>
                    <h2>Backup and restore Kubernetes applications</h2>
                    <p>
                        There are 3 ways to back up applications in Openshift 
                        1. Backing up application resources (best for stateless applications)
                        2. Backing up stateful applications with persistent volumes (best for apps that cannot be paused during backup)
                        3. Backing up stateful applications with specialized tools (database dump tool)
                      Stateless Applications
                      To backup stateless applications export the resource definitions to yaml or json file and redeploy the application.
                      For example: 
                      oc get RESOURCE resource-name -o yaml %3E resource-name.yaml
                      oc get deployment/app-name -o yaml %3E app-name.yaml
                      Stateful Applications
                      To backup stateful applications that cannot be stopped, 
                      You can use a specialized tool such as database back up tools. 
                      To backup an application data from persistent volumes
                      you can scale the deployment to zero replicas.
                      oc scale deployment/app-name --replicas=0
                      create a job that backups up the data
                      scale the application back up to 1
                      oc scale deployment/app-name --replicas=1
                      create pvc for the backup data (In production, you'll need to prepare the database for backup by putting the database into maintenance mode. For example: Flush the table with read lock)

                    </p>
                    <h2>Create volume snapshots and clones</h2>
                    <p dangerouslySetInnerHTML={{
                    __html: `
                        CSI (Container Storage Interface) is a plugin that allows you create volume snapshots or cloes from the default storage classes (cephfs, rbd)
                        When either cloning or creating snapshots, ensure that the provisioner and drivers match. For example, ceph-rbd are for block storage and cephfs are for file storage.
                        Use the corresponding driver and provisioners for the PVC you want to clone or take a snapshot of.
                        To clone a PVC, create a new PVC and use the existing PVC as it's datasource
                        datasource:
                            name: PersistentVolumeClaim
                            kind: existing-pvc-name
                            resources:
                                requests:
                                storage: 1Gi
                        A volume snapshot is similar to a persistent volume, they have the same storage classes. To create a volume snapshot 
                        Steps:
                        Get the volumesnapshot classes
                        oc get volumesnapshotclasses
                        Change the deletion policies to Retain 
                        oc patch volumesnapstopclasses/ocs-storagecluster-cephfsplugin-snapclass --type merge -p '{"deletionPolicy":"Retain"}'
                        oc patch volumesnapstopclasses/ocs-storagecluster-rbdplugin-snapclass --type merge -p '{"deletionPolicy":"Retain"}'
                        Create a snapshot of the persitent volume 
                        Restore the volume snapshot to a different pvc 
                        Attached the pv to a new deployment and verify data
                    `
                    }} />
                  </p>
                </div>
            ),
        },
        {
            title: 'Configure applications to use Red Hat OpenShift Data Foundation object storage',
            content:(
                <div>  
                  <Progress
                    value={90}
                    title="Completion"
                    measureLocation={ProgressMeasureLocation.top}
                  />
                  <h2>Create object bucket claims and access object storage</h2>
                  <p>

                  </p>
                  <h2>Configure applications to use Red Hat OpenShift Data Foundation object storage</h2>
                  <p>

                  </p>
                  <h2>Monitor Red Hat OpenShift Data Foundation object buckets</h2>
                  <p>

                  </p>
                </div>
            ),
        },
        {
            title: 'Control access to resources',
            content:(
                <div>  
                  <Progress
                    value={100}
                    title="Completion"
                    measureLocation={ProgressMeasureLocation.top}
                  />
                  <h2>Define role-based access controls</h2>
                  <p>
                    
                  </p>
                  <h2>Apply permissions to users</h2>
                  <p>
                    
                  </p>
                  <h2>Create and apply secrets to manage sensitive information</h2>
                  <p>
                    
                  </p>
                  <h2>Create service accounts and apply permissions using security context constraints</h2>
                  <p>
                    
                  </p>
                </div>
            ),
        },
    ];

  return (
    <ExamTemplate title="Red Hat Certified Specialist in OpenShift Data Foundations" tabs={tabs} />
  );
};

export default EX370;