import React from 'react';
import { Link } from 'react-router-dom';
import ex188Image from '../../assets/images/54.png';
import ex280Image from '../../assets/images/53.png';
import ex288Image from '../../assets/images/51.png';
import ex267Image from '../../assets/images/45.png';
import ex316Image from '../../assets/images/29.png';
import ex370Image from '../../assets/images/35.png';


function Exams() {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-red-700 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Red Hat OpenShift Almanac Exam Guides
          </h1>
          <p className="text-xl mb-8">
            Exam Prep to Ensure Success on Your Next OpenShift Exam
          </p>
          <Link
            to="/updates"
            className="bg-white text-red-700 font-semibold py-2 px-4 rounded"
          >
            Explore Exam Guides
          </Link>
        </div>
      </div>
      {/* Features Section */}
      <div className="container mx-auto py-16">
        <h2 className="text-3xl font-bold text-center mb-12">
          Exams Guides
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature Item */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/exams/EX188"
            >
            <img
              src={ex188Image}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">EX188</h3>
            <p>
              Red Hat Certified Specialist in Containers.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/exams/EX288"
            >            
            <img
              src={ex288Image}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">EX288</h3>
            <p>
              Red Hat Certified OpenShift Application Developer.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/exams/EX280"
            >
            <img
              src={ex280Image}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">EX280</h3>
            <p>
              Red Hat Certified OpenShift Administrator.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/exams/EX267"
            >
            <img
              src={ex267Image}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">EX267</h3>
            <p>
              Red Hat Certified Specialist in OpenShift AI.
            </p>
            </Link>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/exams/EX316"
            >
            <img
              src={ex316Image}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">EX316</h3>
            <p>
              Red Hat Certified Specialist in OpenShift Virtualization.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
           <Link
            to="/exams/EX370"
            >
            <img
              src={ex370Image}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">EX370</h3>
            <p>
              Red Hat Certified Specialist in OpenShift Data Foundation
            </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exams;
