
// Documentation and Links

//RHOCP  https://docs.redhat.com/en/documentation/openshift_container_platform/4.16

//OpenShift Virtualization: https://docs.redhat.com/en/documentation/openshift_container_platform/4.8/html-single/openshift_virtualization/index


// Product Docs
/*
* RHOCP
* ODF
* GitOps
* OpenShift Pipelines
* ROSA
* ARO
* API Management
* Cluster Manager
* Virtualization
* RHOAI
* RHEL
* Ansible
* Ceph Storage
*/

// Skills Path
/*
    * https://www.redhat.com/en/resources/openshift-skill-paths-datasheet
*/

// Exam Docs

// Class Docs

// Links
// Learning Community Links
import React from 'react';
import { Link } from 'react-router-dom';
import rhocpImage from '../../assets/images/26.svg';
import rhelImage from '../../assets/images/31.png';
import k8sImage from '../../assets/images/53.png';
import podmanImage from '../../assets/images/29.png';
import virtualizationImage from '../../assets/images/53.png';
import ansibleImage from '../../assets/images/33.png';
import rosaImage from '../../assets/images/51.png';
import aroImage from '../../assets/images/51.png';
import odfImage from '../../assets/images/35.png';
import cicdImage from '../../assets/images/26.svg';
import operatorsImage from '../../assets/images/29.png';
import rhoaiImage from '../../assets/images/45.png';
import ocCLIImage from '../../assets/images/49.png';
import podmanCLIImage from '../../assets/images/48.png';
import odoCLIImage from '../../assets/images/47.png';
import virtctlCLIImage from '../../assets/images/50.png';
import rosaCLIImage from '../../assets/images/57.png';
import aroCLIImage from '../../assets/images/55.png';

function Documentation() {
    return (
        <div className="bg-gray-100 min-h-screen">
          {/* Hero Section */}
          <div className="bg-red-700 text-white py-20">
            <div className="container mx-auto text-center">
              <h1 className="text-5xl font-bold mb-4">
                Red Hat OpenShift Documentation
              </h1>
              <p className="text-xl mb-8">
                Official Red Hat Documentation Links
              </p>
              <Link
                to="/docs"
                className="bg-white text-red-700 font-semibold py-2 px-4 rounded"
              >
                Explore Docs
              </Link>
            </div>
          </div>
    
          {/* Features Section */}
          <div className="container mx-auto py-16">
            <h2 className="text-3xl font-bold text-center mb-12">
              Documentation
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Feature Item */}
              <div className="bg-white p-6 rounded shadow">
              <Link
                to="/tutorials/openshift"
                >
                <img
                  src={rhocpImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Red Hat OpenShift Container Platform (RHOCP)</h3>
                <p>
                  Stay up-to-date with the latest releases and features of OpenShift.
                </p>
                </Link>
              </div>
              {/* Repeat for other features */}
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/red_hat_enterprise_linux/9"
                >
                <img
                  src={rhelImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Red Hat Enterprise Linux (RHEL)</h3>
                <p>
                  Step-by-step guides to help you master OpenShift.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
              <Link
                to="https://kubernetes.io/docs/home/"
                >
                <img
                  src={k8sImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Kubernetes</h3>
                <p>
                  Quick access to common commands for podman, oc, virtctl, and more.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://podman.io/docs"
                >
                <img
                  src={podmanImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Podman</h3>
                <p>
                  Learn about the latest in container technology.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/red_hat_openshift_virtualization/"
                >
                <img
                  src={virtualizationImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">OpenShift Virtualization</h3>
                <p>
                  Learn OpenShift virtualization capabilities.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/red_hat_ansible_automation_platform/2.5"
                >
                <img
                  src={ansibleImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Ansible</h3>
                <p>
                  Quick access to common commands for podman, oc, virtctl, and more.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/red_hat_openshift_service_on_aws/"
                >
                <img
                  src={rosaImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Red Hat OpenShfit on AWS (ROSA)</h3>
                <p>
                  Red Hat OpenShift Service on AWS.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="/tutorials/aro"
                >
                <img
                  src={aroImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Azure for Red Hat OpenShift (ARO)</h3>
                <p>
                  Learn OpenShift virtualization capabilities.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/red_hat_openshift_ai_self-managed/"
                >
                <img
                  src={rhoaiImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Red Hat OpenShift AI (RHOAI)</h3>
                <p>
                  Quick access to common commands for podman, oc, virtctl, and more.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/openshift_container_platform/4.4/html/operators/olm-understanding-operatorhub"
                >
                <img
                  src={operatorsImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">OpenShift Operators</h3>
                <p>
                  Red Hat plugin technology to extend the OpenShift functionality.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/red_hat_openshift_data_foundation/4.17"
                >
                <img
                  src={odfImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">OpenShift Data Foundation</h3>
                <p>
                  Red Hat storage solution.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
                <Link
                  to="https://docs.redhat.com/en/documentation/red_hat_openshift_pipelines"
                >
                <img
                  src={cicdImage}
                  alt="Landing"
                  className="w-full h-auto"
                />              
                <h3 className="text-xl font-bold mb-2">Openshift Pipelines</h3>
                <p>
                  Quick access to common commands for podman, oc, virtctl, and more.
                </p>
                </Link>
              </div>
              <div className="bg-white p-6 rounded shadow">
            <Link
            to="https://docs.redhat.com/en/documentation/openshift_container_platform/4.1/html-single/cli_reference/index#cli-logging-in_cli-developer-commands"
            >
            <img
              src={ocCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">oc</h3>
            <p>
              Red Hat OpenShift oc Commands.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="https://docs.podman.io/en/stable/Commands.html"
            >            
            <img
              src={podmanCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">podman</h3>
            <p>
              Red Hat podman Commands to manage images and containers.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="https://docs.redhat.com/en/documentation/openshift_container_platform/4.2/html/cli_tools/openshift-do-developer-cli-odo"
            >
            <img
              src={odoCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">odo</h3>
            <p>
              Red Hat OpenShift odo Commands to manage application on OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/cli/virtctl"
            >
            <img
              src={virtctlCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">virtctl</h3>
            <p>
              Red Hat OpenShift virtctl Commands to manage virtual machines and virtualization resources on OpenShift.
            </p>
            </Link>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli/rosa"
            >
            <img
              src={rosaCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">rosa</h3>
            <p>
              Red Hat rosa Commands to manage underlying Amazon Webservices(AWS) infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/cli/helm"
            >
            <img
              src={virtctlCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">helm</h3>
            <p>
              Red Hat OpenShift virtctl Commands to manage virtual machines and virtualization resources on OpenShift.
            </p>
            </Link>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli/kustomize"
            >
            <img
              src={rosaCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">kustomize</h3>
            <p>
              Red Hat rosa Commands to manage underlying Amazon Webservices(AWS) infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
           <Link
            to="https://docs.ceph.com/en/reef/man/8/ceph/#commands"
            >
            <img
              src={aroCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">ceph</h3>
            <p>
              Red Hat aro Commands to manage underlying Microsoft Azure infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
           <Link
            to="/cli/tekton"
            >
            <img
              src={virtctlCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">tekton</h3>
            <p>
              Red Hat aro Commands to manage underlying Microsoft Azure infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
            </div>
          </div>
        </div>
      );
    }

export default Documentation;
