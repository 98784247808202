import React from 'react';
import CommandTable from '../../components/CommandTable';

function OdoCommands() {
  const odoCommands = [
    {

      /*[student@workstation ~]$ odo login -u developer -p developer \
    https://api.ocp4.example.com:6443
    Connecting to the OpenShift cluster
    
    Login successful.
    
    ...output omitted...
    Use odo to create a project with the odo-deploy-cli name.
    
    [student@workstation ~]$ odo create project odo-deploy-cli
     ✓  Creating the project "odo-deploy-cli" ...
     ✓  Project "odo-deploy-cli" is ready for use
     ✓  New project created and now using project: odo-deploy-cli
      */
      command: 'odo login',
      tool: 'odo',
      function: 'Log in to an OpenShift cluster.',
    },
    {
      command: 'odo new-project <project-name>',
      tool: 'odo',
      function: 'Create a new project/namespace in the OpenShift cluster.',
    },
    {
      command: 'odo new-app <app-name> --template=<template-name> -p parameter-name=value...',
      tool: 'odo',
      function: 'Create a new application in an OpenShift cluster with a template and required parameters.',
    },
    {
      command: 'odo get pods',
      tool: 'odo',
      function: 'List all pods in the current namespace.',
    },
    {
      command: 'odo get nodes',
      tool: 'odo',
      function: 'List all nodes in the OpenShift cluster.',
    },
    {
      command: 'odo describe pod <pod-name>',
      tool: 'odo',
      function: 'Display detailed information about a specific pod.',
    },
    {
      command: 'odo get svc',
      tool: 'odo',
      function: 'List all services in the current namespace.',
    },
    {
      command: 'odo expose svc <service-name>',
      tool: 'odo',
      function: 'Expose a service to create a route, making it externally accessible.',
    },
    {
      command: 'odo delete pod <pod-name>',
      tool: 'odo',
      function: 'Delete a specific pod.',
    },
    {
      command: 'odo adm cordon <node-name>',
      tool: 'odo',
      function: 'Mark a node as unschedulable (cordon), preventing new pods from being scheduled on it.',
    },
    {
      command: 'odo adm drain <node-name>',
      tool: 'odo',
      function: 'Safely evict pods from a node and prepare it for maintenance.',
    },
    {
      command: 'odo scale deployment <name> --replicas=<count>',
      tool: 'odo',
      function: 'Scale a deployment to the desired number of replicas.',
    },
    {
      command: 'odo apply -f <file.yaml>',
      tool: 'odo',
      function: 'Apply changes to resources described in a YAML or JSON file.',
    },
    {
      command: 'odo patch',
      tool: 'odo',
      function: 'Update specific fields of a resource without editing the whole resource definition.',
    },
    {
      command: 'odo adm top nodes',
      tool: 'odo',
      function: 'Show resource usage (CPU/Memory) for all nodes.',
    },
    {
      command: 'odo adm top pods',
      tool: 'odo',
      function: 'Show resource usage (CPU/Memory) for all pods in a namespace.',
    },
    {
      command: 'odo rsh <pod-name>',
      tool: 'odo',
      function: 'Open a shell into a running pod.',
    },
    {
      command: 'odo logs <pod-name>',
      tool: 'odo',
      function: 'View the logs of a pod.',
    },
    {
      command: 'odo exec <pod-name> -- <command>',
      tool: 'odo',
      function: 'Execute a command inside a running pod.',
    },
    {
      command: 'odo ',
      tool: '',
      function: '',
    },
    {
      command: '',
      tool: '',
      function: '',
    },
    //{
    //  command: '',
    //  tool: '',
    //  function: '',
    //},
    // ... add any additional commands
  ];

  return (
    <div className="container mx-auto py-16">
      <h1 className="text-4xl font-bold mb-8">`odo` Command Reference</h1>
      <p className="mb-6">
        The <code>odo</code> command-line tool is the primary tool used for administering OpenShift clusters.
      </p>
      <CommandTable commands={odoCommands} />
    </div>
  );
}

export default OdoCommands;
