import React from 'react';
import { Link } from 'react-router-dom';
import ocCLIImage from '../../assets/images/49.png';
import podmanCLIImage from '../../assets/images/48.png';
import odoCLIImage from '../../assets/images/47.png';
import virtctlCLIImage from '../../assets/images/50.png';
import rosaCLIImage from '../../assets/images/57.png';
import aroCLIImage from '../../assets/images/55.png';


function CliGuides() {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <div className="bg-red-700 text-white py-20">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">
            Red Hat OpenShift Almanac
          </h1>
          <p className="text-xl mb-8">
            CLI Guides For Working with Red Hat OpenShift Effectively
          </p>
          <Link
            to="/updates"
            className="bg-white text-red-700 font-semibold py-2 px-4 rounded"
          >
            Explore CLI Guides
          </Link>
        </div>
      </div>
      {/* Features Section */}
      <div className="container mx-auto py-16">
        <h2 className="text-3xl font-bold text-center mb-12">
          CLI Guides
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Feature Item */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli/oc"
            >
            <img
              src={ocCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">oc</h3>
            <p>
              Red Hat OpenShift oc Commands.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli/podman"
            >            
            <img
              src={podmanCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">podman</h3>
            <p>
              Red Hat podman Commands to manage images and containers.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli/odo"
            >
            <img
              src={odoCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">odo</h3>
            <p>
              Red Hat OpenShift odo Commands to manage application on OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/cli/virtctl"
            >
            <img
              src={virtctlCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">virtctl</h3>
            <p>
              Red Hat OpenShift virtctl Commands to manage virtual machines and virtualization resources on OpenShift.
            </p>
            </Link>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli/rosa"
            >
            <img
              src={rosaCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">rosa</h3>
            <p>
              Red Hat rosa Commands to manage underlying Amazon Webservices(AWS) infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
           <Link
            to="/cli/aro"
            >
            <img
              src={aroCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">aro</h3>
            <p>
              Red Hat aro Commands to manage underlying Microsoft Azure infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
          <Link
            to="/cli/helm"
            >
            <img
              src={virtctlCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">helm</h3>
            <p>
              Red Hat OpenShift virtctl Commands to manage virtual machines and virtualization resources on OpenShift.
            </p>
            </Link>
          </div>
          {/* Repeat for other features */}
          <div className="bg-white p-6 rounded shadow">
            <Link
            to="/cli/kustomize"
            >
            <img
              src={rosaCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">kustomize</h3>
            <p>
              Red Hat rosa Commands to manage underlying Amazon Webservices(AWS) infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
           <Link
            to="https://docs.ceph.com/en/reef/man/8/ceph/#commands"
            >
            <img
              src={aroCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">ceph</h3>
            <p>
              Red Hat aro Commands to manage underlying Microsoft Azure infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
          <div className="bg-white p-6 rounded shadow">
           <Link
            to="/cli/tekton"
            >
            <img
              src={virtctlCLIImage}
              alt="Landing"
              className="w-full h-auto"
            />
            <h3 className="text-xl font-bold mb-2">tekton</h3>
            <p>
              Red Hat aro Commands to manage underlying Microsoft Azure infrastructure hosting OpenShift.
            </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CliGuides;
