import React from 'react';
import ExamTemplate from '../../components/ExamTemplate';
import { Progress, ProgressMeasureLocation } from '@patternfly/react-core';


function EX188() {
  const tabs = [
    {
      title: 'Implement images using Podman',
      content: (
        <div>
          <Progress
            value={10}
            title="Completion"
            measureLocation={ProgressMeasureLocation.top}
          />
          <h3 id="understand-and-use-from-instruction">Understand and Use <code>FROM</code> Instruction</h3>
          <ul>
          <li>The <code>FROM</code> instruction sets the <strong>base image</strong> for your container.</li>
          <li><strong>Example</strong>:<pre><code class="lang-Dockerfile"><span class="hljs-selector-tag">FROM</span> <span class="hljs-selector-tag">ubuntu</span><span class="hljs-selector-pseudo">:20.04</span>
          </code></pre>
          </li>
          <li>This instruction forms the base layer for all subsequent layers in the container image.</li>
          </ul>
          <h3 id="understand-and-use-run-instruction">Understand and Use <code>RUN</code> Instruction</h3>
          <ul>
          <li>The <code>RUN</code> instruction <strong>executes commands</strong> to modify the image.</li>
          <li><strong>Example</strong>:<pre><code class="lang-Dockerfile">RUN apt-<span class="hljs-built_in">get</span> <span class="hljs-keyword">update</span> &amp;&amp; apt-<span class="hljs-built_in">get</span> install -<span class="hljs-keyword">y</span> <span class="hljs-keyword">python3</span>
          </code></pre>
          </li>
          <li>This adds a new layer with the installed Python package.</li>
          </ul>
          <h3 id="understand-and-use-add-and-copy-instructions">Understand and Use <code>ADD</code> and <code>COPY</code> Instructions</h3>
          <ul>
          <li><strong><code>ADD</code> Instruction</strong>: Copies files or directories into the image and can also fetch remote files.<pre><code class="lang-Dockerfile">ADD http<span class="hljs-variable">s:</span>//example.<span class="hljs-keyword">com</span>/<span class="hljs-keyword">file</span>.txt /app/
          </code></pre>
          </li>
          <li><strong><code>COPY</code> Instruction</strong>: Copies files or directories from the build context.<pre><code class="lang-Dockerfile"><span class="hljs-keyword">COPY</span><span class="bash"> ./myapp /app/</span>
          </code></pre>
          </li>
          </ul>
          <h3 id="understand-the-difference-between-add-and-copy-instructions">Understand the Difference Between <code>ADD</code> and <code>COPY</code> Instructions</h3>
          <ul>
          <li><strong><code>ADD</code></strong> can download remote files and unpack compressed archives, while <strong><code>COPY</code></strong> is simpler and more predictable, used for local file copying.</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Manage images',
      content: (
        <div>
        <Progress
          value={30}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h3 id="understand-private-registry-security">Understand Private Registry Security</h3>
          <ul>
          <li>Configure Podman to pull from a private registry by authenticating first.<pre><code class="lang-bash">podman login myregistry<span class="hljs-selector-class">.example</span><span class="hljs-selector-class">.com</span>
          </code></pre>
          </li>
          </ul>
          <h3 id="use-image-tags-and-interact-with-registries">Use Image Tags and Interact with Registries</h3>
          <ul>
          <li><strong>Tag an Image</strong>:<pre><code class="lang-bash">podman <span class="hljs-keyword">tag</span> <span class="hljs-title">myimage</span> myregistry.example.com/myimage:latest
          </code></pre>
          </li>
          <li><strong>Push and Pull Images</strong>:<pre><code class="lang-bash">podman push myregistry<span class="hljs-selector-class">.example</span><span class="hljs-selector-class">.com</span>/myimage:latest
          podman pull myregistry<span class="hljs-selector-class">.example</span><span class="hljs-selector-class">.com</span>/myimage:latest
          </code></pre>
          </li>
          </ul>
          <h3 id="backup-an-image-vs-backup-a-container-state">Backup an Image vs. Backup a Container State</h3>
          <ul>
          <li><strong>Backup Image with Metadata</strong>:<pre><code class="lang-bash"><span class="hljs-selector-tag">podman</span> <span class="hljs-selector-tag">save</span> <span class="hljs-selector-tag">-o</span> <span class="hljs-selector-tag">myimage</span><span class="hljs-selector-class">.tar</span> <span class="hljs-selector-tag">myimage</span><span class="hljs-selector-pseudo">:latest</span>
          </code></pre>
          </li>
          <li><strong>Backup Running Container State</strong>:<pre><code class="lang-bash">podman <span class="hljs-keyword">commit</span> &lt;<span class="hljs-keyword">container</span>-<span class="hljs-keyword">id</span>&gt; myimage:<span class="hljs-keyword">backup</span>
          </code></pre>
          </li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Run containers locally using Podman',
      content: (
        <div>
        <Progress
          value={50}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h3 id="run-containers">Run Containers</h3>
          <ul>
          <li><strong>Run a Container</strong>:<pre><code class="lang-bash">podman <span class="hljs-keyword">run</span><span class="bash"> <span class="hljs-_">-d</span> --name myapp -p 8080:80 myimage</span>
          </code></pre>
          </li>
          </ul>
          <h3 id="get-container-logs-and-events">Get Container Logs and Events</h3>
          <ul>
          <li><strong>View Logs</strong>:<pre><code class="lang-bash"><span class="hljs-attribute">podman logs myapp</span>
          </code></pre>
          </li>
          <li><strong>Listen to Container Events</strong>:<pre><code class="lang-bash"><span class="hljs-attribute">podman events</span>
          </code></pre>
          </li>
          </ul>
          <h3 id="use-podman-inspect-and-specify-environment-parameters">Use Podman Inspect and Specify Environment Parameters</h3>
          <ul>
          <li><strong>Inspect a Container</strong> for details:<pre><code class="lang-bash"><span class="hljs-attribute">podman inspect myapp</span>
          </code></pre>
          </li>
          <li><strong>Run with Environment Variables</strong>:<pre><code class="lang-bash">podman <span class="hljs-keyword">run</span><span class="bash"> <span class="hljs-_">-e</span> <span class="hljs-string">"ENV_VAR=value"</span> myimage</span>
          </code></pre>
          </li>
          </ul>
          <h3 id="expose-public-applications">Expose Public Applications</h3>
          <ul>
          <li><strong>Expose an Application</strong> running inside a container:<pre><code class="lang-bash">podman <span class="hljs-keyword">run</span><span class="bash"> -p 8080:80 myimage</span>
          </code></pre>
          </li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Run multi-container applications with Podman',
      content: (
        <div>
        <Progress
          value={70}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h3 id="create-application-stacks-and-understand-dependencies">Create Application Stacks and Understand Dependencies</h3>
          <ul>
          <li><strong>Use Podman Compose</strong> to manage multi-container applications.<pre><code class="lang-bash"><span class="hljs-attribute">podman-compose up</span>
          </code></pre>
          </li>
          <li>Define <strong>container dependencies</strong> in a <code>docker-compose.yaml</code>:<pre><code class="lang-yaml"><span class="hljs-symbol">version:</span> <span class="hljs-string">'3'</span>
          <span class="hljs-symbol">services:</span>
          <span class="hljs-symbol">  app:</span>
          <span class="hljs-symbol">    image:</span> myapp
          <span class="hljs-symbol">    depends_on:</span>
                - db
          <span class="hljs-symbol">  db:</span>
          <span class="hljs-symbol">    image:</span> postgres
          </code></pre>
          </li>
          </ul>
          <h3 id="work-with-environment-variables-secrets-and-volumes">Work with Environment Variables, Secrets, and Volumes</h3>
          <ul>
          <li><strong>Use Secrets</strong> to secure credentials:<pre><code class="lang-bash">podman secret create mysecret ./secret.txt
          podman <span class="hljs-built_in">run</span> <span class="hljs-comment">--secret mysecret myimage</span>
          </code></pre>
          </li>
          <li><strong>Use Volumes</strong> to persist data:<pre><code class="lang-bash">podman <span class="hljs-keyword">volume</span><span class="bash"> create myvolume
          </span>podman <span class="hljs-keyword">run</span><span class="bash"> -v myvolume:/data myimage</span>
          </code></pre>
          </li>
          </ul>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Troubleshoot containerized applications',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h3 id="understand-and-describe-application-resources">Understand and Describe Application Resources</h3>
          <ul>
          <li><strong>Get Application Logs</strong>:<pre><code class="lang-bash"><span class="hljs-attribute">podman logs myapp</span>
          </code></pre>
          </li>
          </ul>
          <h3 id="inspect-running-applications-and-connect-to-containers">Inspect Running Applications and Connect to Containers</h3>
          <ul>
          <li><strong>Inspect Resources</strong>:<pre><code class="lang-bash"><span class="hljs-attribute">podman inspect myapp</span>
          </code></pre>
          </li>
          <li><strong>Connect to a Running Container</strong>:<pre><code class="lang-bash">podman exec -<span class="hljs-keyword">it</span> myapp /bin/bash
          </code></pre>
          </li>
          </ul>
        </div>
      ),
    },
    // Add more tabs for each exam objective
    {
      title: 'Extra Notes',
      content: (
        <div>
        <Progress
          value={100}
          title="Completion"
          measureLocation={ProgressMeasureLocation.top}
        />
          <h3 id="container-registries">Container Registries</h3>
          <ul>
            <li>Containerfiles consists of base images </li>
            <li>Containers are not full OS</li>
            <li>Universal Base Image: UBI</li>
            <li>Red Hat Container Registries</li>
            <li>registry.access.redhat.com: doesn&#39;t require authentication</li>
            <li>registry.io.redhat.com: requires authentication</li>
            <li>quay.io: image registry not verified</li>
          </ul>
          <ul>
            <li>/etc/containers/registries.conf</li>
            <li>namespace: registry.url/namespace/imagename:latest</li>
          </ul>
          <ul>
          <li>Creating a container registry config</li>
          <li>mdkir -pv ~/.config/containers</li>
          <li>touch ~/.config/containers/registries.conf<ul>
          <li>unqualified-search-registries = [&#39;registry.redhat.io&#39;, &#39;registry.access.redhat.com&#39;]</li>
          <li>[[registry]]</li>
          <li>location=&quot;registry.ocp4.example.com:8443&quot;</li>
          </ul>
          </li>
          <li>Managing images</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <ExamTemplate title="Red Hat Certified Specialist in Containers Exam Objectives" tabs={tabs} />
  );
}

export default EX188;
